<template>
    <div>
        <multiselect v-model="DisplayValue" :options="options" :multiple="false"
            v-bind:placeholder="$t('SubCategoryDropdown.PleaseSelectSubCategory')" track-by="dropDownName"
            :clear-on-select="false" :show-labels="false" label="dropDownName" :preselect-first="true"
            v-bind:class="($i18n.locale == 'en' || isLeftToRight()) ? 'text-left ' : 'arabicLanguage '">
            <!--<p slot="noResult" class="text-danger"> Oops! No SubCategory found.</p>-->

            <a slot="noResult" class="btn btn-primary " v-on:click="AddSubCategory('Add')"
                v-if="isValid('CanAddSubCategory')">Add New</a><br />

        </multiselect>
        <modal :show="show" v-if="show">

            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="myModalLabel"> Add Expense SubCategory</h5>
                </div>
                <div class="modal-body">
                    <div class="row ">
                        <div class="form-group has-label col-sm-12 "
                            v-bind:class="{ 'has-danger': $v.subCategory.categoryId.$error }">
                            <label class="text  font-weight-bolder">
                                Expense Category: <span class="text-danger"> *</span>
                            </label>
                            <citydropdown v-model="$v.subCategory.categoryId.$model" v-bind:values="subCategory.categoryId">
                            </citydropdown>

                        </div>
                        <div class="form-group has-label col-sm-12 "
                            v-bind:class="{ 'has-danger': $v.subCategory.name.$error }">
                            <label class="text  font-weight-bolder">
                                Name: <span class="text-danger"> *</span>
                            </label>
                            <input class="form-control" v-model="$v.subCategory.name.$model" type="text" />
                            <span v-if="$v.subCategory.name.$error" class="error">
                                <span v-if="!$v.subCategory.name.required"> Name is required</span>
                                <span v-if="!$v.subCategory.name.maxLength">Exceed max length</span>
                            </span>
                        </div>

                        <div class="form-group has-label col-sm-12 form-group">
                            <label class="text  font-weight-bolder">{{ $t('SubCategoryDropdown.Description') }} :</label>
                            <div v-bind:class="{ 'has-danger': $v.subCategory.description.$error }">
                                <textarea rows="3" class="form-control" v-model="$v.subCategory.description.$model" />
                                <span v-if="$v.subCategory.description.$error" class="error text-danger">

                                </span>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <div class="checkbox form-check-inline mx-2">
                                <input type="checkbox" id="inlineCheckbox1" v-model="subCategory.isActive">
                                <label for="inlineCheckbox1"> {{ $t('addBrand.Active') }} </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-right" v-if="!loading">

                    <button type="button" class="btn btn-primary" v-bind:disabled="$v.subCategory.$invalid"
                        v-on:click="SaveSubCategory"> {{ $t('CityDropdown.btnSave') }}</button>
                    <button type="button" class="btn btn-danger  mr-3 " v-on:click="close()">{{
                        $t('CityDropdown.btnClear') }}</button>

                </div>

                <div v-else>
                    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
                </div>

            </div>
        </modal>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { required, maxLength, requiredIf } from "vuelidate/lib/validators"
import clickMixin from '@/Mixins/clickMixin'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    mixins: [clickMixin],
    name: 'subcategorydropdown',
    props: ["values", "categoryId"],

    components: {
        Multiselect,
        Loading
    },
    data: function () {
        return {
            arabic: '',
            english: '',
            options: [],
            value: '',
            show: false,
            type: '',
            rendered: 0,
            subCategory: {
                id: '00000000-0000-0000-0000-000000000000',
                code: '',
                name: '',
                nameArabic: '',
                description: '',
                categoryId: this.categoryId,
                isActive: true
            },
            loading: false,
            render: 0
        }
    },
    validations: {
        subCategory:
        {
            code: {
                required
            },
            name: {
                maxLength: maxLength(50)
            },
            nameArabic: {
                required: requiredIf((x) => {
                    if (x.name == '' || x.name == null)
                        return true;
                    return false;
                }),
                maxLength: maxLength(50)
            },
            categoryId: {
                required
            },
            description: {}
        }
    },
    methods: {
        getData: function () {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            this.$https.get('/Product/GetSubCategoryInformation?isActive=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {


                if (response.data != null) {
                    response.data.results.subCategories.forEach(function (cat) {

                        root.options.push({
                            id: cat.id,
                            dropDownName: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (cat.name != '' ? cat.code + ' ' + cat.name : cat.code + ' ' + cat.nameArabic) : (cat.nameArabic != '' ? cat.code + ' ' + cat.nameArabic : cat.code + ' ' + cat.name),
                            name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (cat.name != '' ? cat.name : cat.nameArabic) : (cat.nameArabic != '' ? cat.nameArabic : cat.name)

                        })
                    })
                }
            }).then(function () {
                root.value = root.options.find(function (x) {

                    return x.id == root.values;
                })
            });
        },
        getCategoryBaseData: function (id) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            // eslint-disable-line
            this.$https.get('/Product/GetSubCategoryInformation?categoryId=' + id + '&isActive=' + true, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                // eslint-disable-line
                if (response.data != null) {
                    root.options = []
                    response.data.results.subCategories.forEach(function (scat) {

                        root.options.push({
                            id: scat.id,
                            dropDownName: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (scat.name != '' ? scat.code + ' ' + scat.name : scat.code + ' ' + scat.nameArabic) : (scat.nameArabic != '' ? scat.code + ' ' + scat.nameArabic : scat.code + ' ' + scat.name),
                            name: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? (scat.name != '' ? scat.name : scat.nameArabic) : (scat.nameArabic != '' ? scat.nameArabic : scat.name)
                        })
                    })
                }
                else {
                    root.options.push({
                        id: '',
                        name: ''
                    })
                }
            }).then(function () {
                root.value = root.options.find(function (x) {

                    return x.id == root.values;
                })
            });
        },
        AddSubCategory: function (type) {
            this.$v.$reset();
            this.AutoIncrementCode();
            this.subCategory = {
                id: '00000000-0000-0000-0000-000000000000',
                code: '',
                name: '',
                nameArabic: '',
                description: '',
                categoryId: this.categoryId,
                isActive: true
            }

            this.show = !this.show;
            this.type = type;
            this.rendered++;
        },
        close: function () {
            this.show = false;
        },
        SaveSubCategory: function () {
            var root = this;
            this.loading = true;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            this.$https.post('/Product/SaveSubCategoryInformation', this.subCategory, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {

                    if (response.data.isSuccess == true) {
                        root.show = false;
                        root.getData();
                    }
                    else {
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                            icon: 'error',
                            text: "Your Subcategory Already Exist!",
                            type: 'error',
                            confirmButtonClass: "btn btn-danger",
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true,
                        });
                    }

                }).catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Something Went Wrong!' : 'هل هناك خطب ما!',
                            text: error.response.data,

                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false
                })
                .finally(() => root.loading = false)
        },
        AutoIncrementCode: function () {
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https
                .get('/Product/SubCategoryAutoGenerateCode', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.subCategory.code = response.data;
                        root.render++
                    }
                });
        }
    },
    computed: {
        DisplayValue: {
            get: function () {
                if (this.value != "" || this.value != undefined) {
                    return this.value;
                }
                return this.values;
            },
            set: function (value) {
                this.value = value;
                this.$emit('input', value.id);
            }
        }
    },
    mounted: function () {
        this.getData();
        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');
        console.log(this.categoryId)
        // if (this.categoryId != null) {
        //     // eslint-disable-line
        //     this.getCategoryBaseData(this.categoryId);
        // }
    },
}
</script>